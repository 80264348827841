<template>
  <div>
    <div
      v-if="warehouse === null || warehouse === undefined"
      class="m-5 p-5 text-center bold-40"
    >
      {{ $t("productHistory.noWarehouseSelected2") }}
    </div>
    <div
      v-else-if="selectedWarehouse?.type !== 3"
      class="m-5 p-5 text-center bold-40"
    >
      {{ $t("order.thisWarehouseNotARetailType") }}
    </div>
    <div class="row gap-3" v-else>
      <div class="text-end">
        <button v-on:click="loadData" class="btn btn-type-2 btn-small">
          <span
            ><BaseIcon name="refresh" />
            {{ $t("salesCollectList.refresh") }}</span
          >
        </button>
      </div>
      <div class="col-12" v-if="orders === undefined">
        <div
          v-for="i in 3"
          :key="i"
          class="nature-white-box no-min-height mb-3"
        >
          <div class="row mt-3 mb-4 ms-2">
            <div class="col">
              <h2>
                <Skeletor :width="Math.random() * 21 + 30 + '%'" />
              </h2>
              <div>
                <span>
                  <Skeletor :width="Math.random() * 10 + 10 + '%'" />
                </span>
              </div>
            </div>
          </div>
          <Skeletor class="p-3 my-1" :width="Math.random() * 30 + 70 + '%'" />
          <Skeletor
            class="p-3 my-1"
            :width="Math.random() * 30 + 70 + '%'"
            v-if="Math.random() > 0.3"
          />
          <Skeletor
            class="p-3 my-1"
            :width="Math.random() * 30 + 70 + '%'"
            v-if="Math.random() > 0.6"
          />
          <Skeletor
            class="p-3 my-1"
            :width="Math.random() * 30 + 70 + '%'"
            v-if="Math.random() > 0.9"
          />
        </div>
      </div>
      <div v-else class="col-12">
        <div v-if="orders.length === 0" class="text-center mt-4">
          <Vue3Lottie
            :animationData="emptyJSON"
            :height="160"
            :width="300"
            :key="key"
          />
          <div class="bold-40 m-2 p-5">
            {{ $t("salesProductList.thereIsNothingToPack") }}
          </div>
        </div>
        <div
          class="nature-white-box no-min-height"
          v-for="order in filteredOrders"
          :key="order.id"
        >
          <div class="white-box-top"></div>
          <div class="row mt-3 mb-4 ms-2">
            <div class="col">
              <h2>{{ order.first_name }} {{ order.last_name }}</h2>
              <div>
                <span>
                  <router-link
                    :to="'/sales/' + order.id"
                    class="text-start"
                    target="_blank"
                  >
                    {{ order.pretty_id }}
                  </router-link>
                </span>
                <span
                  v-if="type === 'return'"
                  class="ms-2 text-center w-20 d-inline-block"
                  >{{ order.public_tracking_code }}</span
                >
                <span v-if="type === 'return'" class="ms-2">
                  <span
                    class="badge py-0"
                    :class="deliveredStatuses[order.delivered_status].color"
                  >
                    <BaseIcon
                      :name="
                        deliveredStatuses[order.delivered_status]
                          ? deliveredStatuses[order.delivered_status].icon
                          : 'box-search'
                      "
                    />
                    {{
                      deliveredStatuses[order.delivered_status]
                        ? deliveredStatuses[order.delivered_status].name
                        : "Waiting"
                    }}
                  </span>
                </span>

                <span class="ms-1" v-if="order.comment">{{
                  $t(`salesProductList.comment`, { comment: order.comment })
                }}</span>
              </div>
            </div>
            <div
              class="col-auto custom-input whitebg-input"
              v-if="type !== 'return'"
            >
              <div class="input-group mb-1 px-0 py-2">
                <span class="input-group-text">
                  <button
                    class="btn btn-link"
                    v-on:click="
                      () => {
                        if (order.packages > 1) {
                          order.packages--;
                        }
                      }
                    "
                  >
                    <BaseIcon name="minus-circle" />
                  </button>
                  <input
                    type="number"
                    class="form-control w-4rem"
                    v-model="order.packages"
                  />
                  <button
                    class="btn btn-link"
                    v-on:click="
                      () => {
                        order.packages++;
                      }
                    "
                  >
                    <BaseIcon name="plus-circle" />
                  </button>
                </span>
              </div>
            </div>
            <div class="col-auto mt-2 me-3">
              <button
                class="btn btn-success p-2"
                data-bs-placement="top"
                :title="$t(`salesProductList.printLabel`)"
                :disabled="order.loadShipBtn"
                v-on:click="
                  printLabel(order.tracking_code, order.delivery_method_id)
                "
                :class="{ 'loading-spin': order.loadShipBtn }"
                v-if="order.tracking_code !== undefined"
              >
                <BaseIcon name="printer" />
              </button>
              <button
                class="btn btn-primary p-2"
                data-bs-placement="top"
                :title="$t(`order.createDelivery`)"
                :disabled="order.loadShipBtn"
                v-on:click="generateShipping(order)"
                :class="{ 'loading-spin': order.loadShipBtn }"
                v-else-if="type !== 'return'"
              >
                <BaseIcon name="box-add" />
              </button>
            </div>
            <div class="col-6 custom-input whitebg-input">
              <label class="input-group mb-1">
                <span class="input-group-text me-3">
                  <BaseIcon
                    :name="order.show_success ? 'tick-circle' : 'scanner'"
                  />
                </span>
                <input
                  :placeholder="$t(`salesProductList.readBarcode`)"
                  type="text"
                  class="form-control"
                  v-model="order.barcode"
                  :disabled="order.disable"
                  :ref="
                    order.order_package_id
                      ? 'serach_box_' + order.id + '_' + order.order_package_id
                      : 'serach_box_' + order.id
                  "
                  v-on:change="
                    addToPackage(
                      order.id,
                      $event.target.value,
                      order.order_package_id
                    )
                  "
                />
              </label>
            </div>
          </div>
          <one-by-one-table
            v-model:rows="rows"
            v-model:items="order.orderItems"
            :show-del="this.type === 'return'"
            :show-add="false"
            :show-edit="false"
            v-on:delete="(d) => cancelReturnModal(order, d)"
            type="products"
          />
        </div>
      </div>
      <ScanModal
        ref="packagingModal"
        :title="packagingInfo.status"
        :text="packagingInfo.text"
        :icon="packagingInfo.icon"
      />
      <ConfirmModal
        ref="confirmModal"
        :title="$t(`salesProductList.removeItem`)"
        text="Are you sure you want to remove this item from the return list?"
        cancel-button="No, keep it"
        ok-button="Yes, remove"
        v-on:success="cancelReturn"
      />
    </div>
  </div>
</template>
<script>
import OneByOneTable from "../../components/lists/OneByOneTable";
import http from "@/modules/http";
import { defineAsyncComponent } from "vue";
import BaseIcon from "@/components/icons/BaseIcon";
import ScanModal from "@/components/modals/ScanModal";
import ws from "@/modules/ws";
import { useStore } from "vuex";
import ConfirmModal from "@/components/modals/ConfirmModal";
import printJS from "print-js";
import store from "@/store";
import deliveredStatuses from "@/modules/DeliveredStatuses";
import tenant from "../../modules/tenant";
import { Vue3Lottie } from "vue3-lottie";
import emptyJSON from "../../assets/animations/ghost.json";

export default {
  name: "SalesProductList",
  components: { Vue3Lottie, ConfirmModal, ScanModal, BaseIcon, OneByOneTable },
  data() {
    return {
      store: useStore(),
      deliveredStatuses: deliveredStatuses,
      emptyJSON: emptyJSON,
      rows: [
        {
          name: "image",
          key: "image",
          type: "image",
          show: true,
          editable: false,
        },
        {
          name: "serial",
          key: "serial",
          component: defineAsyncComponent(() =>
            import("../../components/lists/listItems/Serial")
          ),
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Serial",
          key: "serial",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Name",
          key: "name",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Color",
          key: "caracteristics_1",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Size",
          key: "caracteristics_2",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Quantity",
          key: "quantity",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Left",
          key: "need_to_sent_quantity",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Price",
          key: "payed_price",
          type: "price",
          show: true,
          editable: false,
        },
        {
          name: "SKU",
          key: "sku",
          type: "string",
          show: true,
          editable: false,
        },
      ],
      orders: undefined,
      itemsById: {},
      ordersById: {},
      cancelItem: {},
      packagingInfo: {
        icon: null,
        status: "",
        text: "",
      },
    };
  },
  computed: {
    filteredOrders() {
      if (this.q === null || this.q.length < 2) {
        return this.orders;
      }
      let spacePos = this.q.indexOf(" ");
      if (spacePos < 2) {
        spacePos = this.q.length;
      }
      return this.orders.filter(
        (e) =>
          e.orderItems.findIndex((g) => g.sku.indexOf(this.q) === 0) > -1 ||
          (e?.public_tracking_code?.indexOf(
            this.q.substr(2, this.q.length - 4)
          ) > -1 &&
            e?.public_tracking_code?.indexOf(
              this.q.substr(2, this.q.length - 4)
            ) < 6) ||
          e?.pretty_id?.indexOf(this.q) > -1 ||
          (e?.first_name?.indexOf(this.q.substr(0, spacePos)) > -1 &&
            e?.last_name?.indexOf(this.q.substr(spacePos, 100)) > -1) ||
          (e?.last_name?.indexOf(this.q.substr(0, spacePos)) > -1 &&
            e?.first_name?.indexOf(this.q.substr(spacePos, 100)) > -1)
      );
    },
    type() {
      return this.$router.currentRoute.value.meta?.type;
    },
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    warehouses() {
      return this.store.state.warehouses;
    },
    selectedWarehouse() {
      return this.warehouses.find((e) => e.id === this.warehouse);
    },
    q() {
      return this.store.state.topBar.q;
    },
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
  },
  watch: {
    warehouse() {
      this.loadData();
    },
  },
  mounted() {
    if (this.type === "return") {
      this.rows.push({
        name: "Returned",
        key: "returned",
        type: "boolean",
        show: true,
        editable: false,
        center: true,
      });
    } else {
      this.rows.push({
        name: "Collected",
        key: "collected",
        type: "boolean",
        show: true,
        editable: false,
        center: true,
      });
      this.rows.push({
        name: "In package",
        key: "in_package",
        type: "boolean",
        show: true,
        editable: false,
        center: true,
      });
    }
    ws.init();

    ws.subscribe("modify", "orderItem", (e) => {
      if (
        e.rowKey === "collected" ||
        e.rowKey === "in_package" ||
        e.rowKey === "quantity" ||
        e.rowKey === "returned" ||
        e.rowKey === "need_to_sent_quantity"
      ) {
        let obj = this.itemsById[e.objectId];
        if (obj !== undefined) {
          obj[e.rowKey] = e.value;
        }
      }
    });

    this.loadData();
  },
  methods: {
    loadData() {
      this.orders = undefined;
      if (this.warehouse === undefined || this.warehouse === null) {
        return;
      }
      http
        .fetch(
          `/orders/${
            this.type === "return" ? "returns" : "products"
          }?warehouse=${this.warehouse}`
        )
        .then((data) => {
          this.orders = Object.values(data.data);
          this.ordersById = {};
          for (const key in this.orders) {
            this.orders[key].packages = 1;
            this.ordersById[
              this.orders[key].order_package_id
                ? this.orders[key].id + "_" + this.orders[key].order_package_id
                : this.orders[key].id
            ] = this.orders[key];
            for (const itemKey in this.ordersById[
              this.orders[key].order_package_id
                ? this.orders[key].id + "_" + this.orders[key].order_package_id
                : this.orders[key].id
            ].orderItems) {
              let item = this.orders[key].orderItems[itemKey];
              this.itemsById[this.orders[key].orderItems[itemKey].id] = item;

              if (item.image !== null && item.image !== undefined) {
                item.image = this.mediaServer + "/" + item.image;
              }
            }
          }
        });
    },
    generateShipping(order) {
      order.loadShipBtn = true;
      http
        .fetch(
          "/orders/delivery",
          {
            order_package_id: order.order_package_id,
            packages: order.packages,
          },
          true
        )
        .then((data) => {
          order.tracking_code = data.tracking_code;
          order.delivery_method_id = data.delivery_method_id;
          order.loadShipBtn = false;
          this.printLabel(order.tracking_code, order.delivery_method_id);
        })
        .catch(() => {
          order.loadShipBtn = false;
        });
    },
    printLabel(code, method) {
      console.log("initialize printing");
      const url = `${tenant.getApiBaseUrl()}/deliveries/label/${method}?code=${code}&_token=${
        store.state.token
      }`;
      printJS({
        printable: url,
        type: "pdf",
        onLoadingStart: () => {
          console.log("printer loading..");
        },
        onLoadingEnd: () => {
          console.log("start printing");
        },
        onError: (err) => {
          window.open(url, "_blank");
          console.log("printer error:");
          console.log(err);
        },
      });
    },
    cancelReturnModal(order, item) {
      if (this.type !== "return") {
        return;
      }
      this.cancelItem = {
        order: order,
        item: item,
      };
      this.$refs.confirmModal.showModal();
    },
    cancelReturn() {
      if (this.type !== "return") {
        return;
      }
      http
        .fetch(`/orders/${this.cancelItem.order.id}/cancel-return`, {
          item: this.cancelItem.item.id,
        })
        .then(() => {
          let i = this.ordersById[
            this.cancelItem.order.order_package_id
              ? this.cancelItem.order.id +
                "_" +
                this.cancelItem.order.order_package_id
              : this.cancelItem.order.id
          ].orderItems.indexOf(this.cancelItem.item);
          if (i > -1) {
            this.ordersById[
              this.cancelItem.order.order_package_id
                ? this.cancelItem.order.id +
                  "_" +
                  this.cancelItem.order.order_package_id
                : this.cancelItem.order.id
            ].orderItems.splice(i, 1);
          }
        });
    },
    addToPackage(order, barcode, package_id = null) {
      let oid = package_id ? order + "_" + package_id : order;

      if (barcode === null && barcode.length < 3) {
        return;
      }

      this.packagingInfo.icon = 3;
      this.packagingInfo.status = "Searching";
      this.packagingInfo.text = "just a few moments..";
      //this.$refs.packagingModal.showModal();

      this.ordersById[oid].barcode = "";
      this.ordersById[oid].disable = true;

      let element = this.ordersById[oid].orderItems.find(
        (e) => e.sku === barcode
      );
      if (element !== undefined) {
        this.packaging(order, element.id, oid);
      } else {
        this.packagingInfo.icon = 2;
        this.packagingInfo.status = "Barcode not found";
        this.packagingInfo.text =
          "We cannot find any item with this barcode in this transfer.";
        this.$refs.packagingModal.showModal();
        this.ordersById[oid].disable = false;
      }
    },
    packaging(order, item, oid) {
      http
        .fetch(
          `/orders/${order}/${
            this.type === "return" ? "returned" : "packaging"
          }`,
          { item: item }
        )
        .then((data) => {
          let e = this.itemsById[item];
          if (e === undefined) {
            return;
          }
          e.need_to_sent_quantity = data.statuses?.need_to_sent_quantity;

          if (data.statuses?.need_to_sent_quantity === 0) {
            e.order_package_id = true;
            e.in_package = true;
          }

          if (data.statuses?.need_to_sent_quantity === null) {
            e.returned = true;
          }

          this.packagingInfo.status =
            this.type === "return"
              ? "The item received in the warehouse"
              : "Item added to the package";
          this.packagingInfo.text =
            this.type === "return"
              ? "The item received in the warehouse"
              : "Item added to the package";
          this.packagingInfo.icon = 1;
          this.ordersById[oid].show_success = true;
          this.ordersById[oid].disable = false;
          setTimeout(() => {
            this.$refs["serach_box_" + oid][0].focus();
          }, 40);
          setTimeout(() => {
            this.ordersById[oid].show_success = false;
          }, 600);
          // this.$refs.packagingModal.showModal();
        })
        .catch((data) => {
          this.ordersById[oid].disable = false;
          this.packagingInfo.icon = 2;
          this.packagingInfo.status = "Error";
          this.packagingInfo.text = data.message;
          this.$refs.packagingModal.showModal();
        });
    },
  },
};
</script>
